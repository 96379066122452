import { useRef, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

export const VideoComponent = ({ videoFile, className = "" }) => {
  const videoRef = useRef(null);
  const [playing, setPlaying] = useState(false);
  // Play the video if it's not already playing
  const playAnimation = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      const playPromise = videoRef.current.play();

      if (playPromise !== undefined) {
        playPromise
          .then((_) => {
            // Automatic playback started!
            // Show playing UI.
            // We can now safely pause video...
            setPlaying(true);
          })
          .catch((error) => {
            // Auto-play was prevented
            // Show paused UI.
          });
      }
    }
  };

  // Stop the video and reset to the start when out of view
  const goToAndStop = () => {
    if (videoRef.current && playing) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0; // Reset to the start
    }
  };

  const { ref, inView } = useInView({
    triggerOnce: false, // Keep checking as long as it's in view
    threshold: 0.5, // Play when 50% of the video is visible
  });

  useEffect(() => {
    // If the video is in view, start playing, else reset it to start
    if (inView) {
      playAnimation();
    } else {
      goToAndStop();
    }
  }, [inView]);

  return (
    <div ref={ref}>
      <video
        className={className}
        src={videoFile}
        preload="auto" // Preload the video to improve smooth playback
        loop={false} // Disable looping
        ref={videoRef}
        autoPlay={false} // Do not autoplay unless in view
        muted // Muted for autoplay support on mobile
        controls={false} // Disable controls if not needed
      />
    </div>
  );
};
